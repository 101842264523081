

<template>
  <div class="bg" >
    <div id="adgshp855357783"></div>
  </div>
</template>
<script type="text/javascript">

export default{
  created() {
    /* eslint-disable no-empty */
function AgdSherpa(n, t) {
  this.ReferenceKey = n.ReferenceKey || "";
  this.OverideConf = n.OverideConf;
  this.width = n.width;
  this.height = n.height;
  this.Layout = n.Layout;
  this.crt = n.crt;
  this.version = n.version;
  this.id = n.id;

  this.mobileOptimize = n.mobileOptimize;
  this.container = document.getElementById(this.id);
  this.Cid = n.Cid;
  this.Siteid = n.Siteid;
  this.Site_id = n.Site_id;
  this.Hid = n.Hid;
  this.Area = n.Area;
  this.City = n.City;
  this.Poi = n.Poi;
  this.DestinationName = n.DestinationName;
  this.borderThickness = n.borderThickness;
  this.landingPath = this._getLadingPath(t);
  this.baseUrl = "//sherpa.agoda.com/" + this.landingPath;
  this.Language = n.Language || "en-us";
  this.Theme = n.Theme;
  this.Style = n.Style;
  this.AutoSgStyle = n.AutoSgStyle;
  this.BtnStyle = n.BtnStyle;
  this.AgdLogoType = n.AgdLogoType;
  this.parentPageUrl = window.location.href;
  this.protocol = window.location.protocol;
  this.iframeName = "iv_" + this.id;
  this.name = n.name || "";
  this.proxy = n.obj;
  this.proxy = new Porthole.WindowProxy(this.parentPageUrl, this.iframeName);
  this.sherpaRenderUrl = "";
  this.sherpaQueryStringsConfiguration = [
    { key: "ReferenceKey", value: this.ReferenceKey, type: "string" },
    { key: "Language", value: this.Language, type: "string" },
    { key: "Hg", value: this.height, type: "string" },
    { key: "Wd", value: this.width, type: "string" },
    { key: "OverideConf", value: this.OverideConf, type: "boolean" },
    { key: "Layout", value: this.Layout, type: "string" },
    { key: "MobOptimized", value: this.mobileOptimize, type: "boolean" },
    { key: "BtnStyle", value: this.BtnStyle, type: "string" },
    { key: "AgdLogoType", value: this.AgdLogoType, type: "string" },
    { key: "Style", value: this.Style, type: "string" },
    { key: "AutoSgStyle", value: this.AutoSgStyle, type: "string" },
    { key: "Theme", value: this.Theme, type: "string" },
    { key: "Ver", value: this.version, type: "string" },
    { key: "crt", value: this.crt, type: "string" },
    { key: "BorderThickness", value: this.borderThickness, type: "string" },
    { key: "Cid", value: this.Cid, type: "string" },
    { key: "Siteid", value: this.Siteid, type: "string" },
    { key: "Site_id", value: this.Site_id, type: "string" },
    { key: "Hid", value: this.Hid, type: "string" },
    { key: "City", value: this.City, type: "string" },
    { key: "Poi", value: this.Poi, type: "string" },
    { key: "Area", value: this.Area, type: "string" },
    { key: "DestinationName", value: this.DestinationName, type: "string" },
  ];
  var i = this;
  this.proxy.addEventListener(function (n) {
    var t;
    n &&
      n.data &&
      (n.data.action === "resize" && n.data.width && n.data.height
        ? (i.container.setAttribute(
            "style",
            "height:" +
              n.data.height +
              " !important; width:" +
              n.data.width +
              " !important;"
          ),
          (t = document.getElementById(i.iframeName)),
          t.style.setProperty("width", "100%", "important"),
          t.style.setProperty("min-width", n.data.width, "important"),
          (t.style.zIndex = 1),
          (t.style.position = "relative"))
        : n.data.action === "expand" &&
          n.data.height &&
          ((t = document.getElementById(i.iframeName)),
          t.setAttribute(
            "style",
            "height:" +
              n.data.height +
              " !important; width: 100% !important; z-index:1;position:relative;"
          )));
  });
}
(function (n) {
  var i = !1,
    u = /xyz/.test(function () {
      xyz;
    })
      ? /\b_super\b/
      : /.*/,
    r = function () {},
    t;
  r.extend = function (n) {
    function f() {
      !i && this.init && this.init.apply(this, arguments);
    }
    var o = this.prototype,
      e,
      t;
    i = !0;
    e = new this();
    i = !1;
    for (t in n)
      e[t] =
        typeof n[t] == "function" && typeof o[t] == "function" && u.test(n[t])
          ? (function (n, t) {
              return function () {
                var r = this._super,
                  i;
                return (
                  (this._super = o[n]),
                  (i = t.apply(this, arguments)),
                  (this._super = r),
                  i
                );
              };
            })(t, n[t])
          : n[t];
    return (
      (f.prototype = e), (f.prototype.constructor = f), (f.extend = r.extend), f
    );
  };
  t = {
    debug: !1,
    trace: function (t) {
      this.debug && n.console !== undefined && n.console.log("Porthole: " + t);
    },
    error: function (t) {
      typeof n.console !== undefined &&
        typeof n.console.error == "function" &&
        n.console.error("Porthole: " + t);
    },
  };
  t.WindowProxy = function () {};
  t.WindowProxy.prototype = {
    post: function () {},
    addEventListener: function () {},
    removeEventListener: function () {},
  };
  t.WindowProxyBase = r.extend({
    init: function (t) {
      t === undefined && (t = "");
      this.targetWindowName = t;
      this.origin = n.location.protocol + "//" + n.location.host;
      this.eventListeners = [];
    },
    getTargetWindowName: function () {
      return this.targetWindowName;
    },
    getOrigin: function () {
      return this.origin;
    },
    getTargetWindow: function () {
      return t.WindowProxy.getTargetWindow(this.targetWindowName);
    },
    post: function (t, i) {
      i === undefined && (i = "*");
      this.dispatchMessage({
        data: t,
        sourceOrigin: this.getOrigin(),
        targetOrigin: i,
        sourceWindowName: n.name,
        targetWindowName: this.getTargetWindowName(),
      });
    },
    addEventListener: function (n) {
      return this.eventListeners.push(n), n;
    },
    removeEventListener: function (n) {
      var t;
      try {
        t = this.eventListeners.indexOf(n);
        this.eventListeners.splice(t, 1);
      } catch (i) {
        this.eventListeners = [];
      }
    },
    dispatchEvent: function (n) {
      for (var i = 0; i < this.eventListeners.length; i++)
        try {
          this.eventListeners[i](n);
        } catch (r) {
          t.error(r);
        }
    },
  });
  t.WindowProxyLegacy = t.WindowProxyBase.extend({
    init: function (n, i) {
      this._super(i);
      n !== null
        ? ((this.proxyIFrameName = this.targetWindowName + "ProxyIFrame"),
          (this.proxyIFrameLocation = n),
          (this.proxyIFrameElement = this.createIFrameProxy()))
        : ((this.proxyIFrameElement = null),
          t.trace("proxyIFrameUrl is null, window will be a receiver only"),
          (this.post = function () {
            throw new Error("Receiver only window");
          }));
    },
    createIFrameProxy: function () {
      var n = document.createElement("iframe");
      return (
        n.setAttribute("id", this.proxyIFrameName),
        n.setAttribute("name", this.proxyIFrameName),
        n.setAttribute("src", this.proxyIFrameLocation),
        n.setAttribute("frameBorder", "1"),
        n.setAttribute("scrolling", "auto"),
        n.setAttribute("width", 30),
        n.setAttribute("height", 30),
        n.setAttribute("style", "position: absolute; left: -100px; top:0px;"),
        n.style.setAttribute &&
          n.style.setAttribute(
            "cssText",
            "position: absolute; left: -100px; top:0px;"
          ),
        document.body.appendChild(n),
        n
      );
    },
    dispatchMessage: function (i) {
      var u = n.encodeURIComponent,
        r;
      this.proxyIFrameElement &&
        ((r = this.proxyIFrameLocation + "#" + u(t.WindowProxy.serialize(i))),
        this.proxyIFrameElement.setAttribute("src", r),
        (this.proxyIFrameElement.height =
          this.proxyIFrameElement.height > 50 ? 50 : 100));
    },
  });
  t.WindowProxyHTML5 = t.WindowProxyBase.extend({
    init: function (n, t) {
      this._super(t);
      this.eventListenerCallback = null;
    },
    dispatchMessage: function (n) {
      this.getTargetWindow().postMessage(
        t.WindowProxy.serialize(n),
        n.targetOrigin
      );
    },
    addEventListener: function (t) {
      if (this.eventListeners.length === 0) {
        var i = this;
        n.addEventListener
          ? ((this.eventListenerCallback = function (n) {
              i.eventListener(i, n);
            }),
            n.addEventListener("message", this.eventListenerCallback, !1))
          : n.attachEvent &&
            ((this.eventListenerCallback = function () {
              i.eventListener(i, n.event);
            }),
            n.attachEvent("onmessage", this.eventListenerCallback));
      }
      return this._super(t);
    },
    removeEventListener: function (t) {
      this._super(t);
      this.eventListeners.length === 0 &&
        (n.removeEventListener
          ? n.removeEventListener("message", this.eventListenerCallback)
          : n.detachEvent &&
            (typeof n.onmessage == "undefined" && (n.onmessage = null),
            n.detachEvent("onmessage", this.eventListenerCallback)),
        (this.eventListenerCallback = null));
    },
    eventListener: function (n, i) {
      var r = t.WindowProxy.unserialize(i.data);
      r &&
        (n.targetWindowName === "" ||
          r.sourceWindowName == n.targetWindowName) &&
        n.dispatchEvent(new t.MessageEvent(r.data, i.origin, n));
    },
  });
  n.postMessage
    ? (t.trace("Using built-in browser support"),
      (t.WindowProxy = t.WindowProxyHTML5.extend({})))
    : (t.trace("Using legacy browser support"),
      (t.WindowProxy = t.WindowProxyLegacy.extend({})));
  t.WindowProxy.serialize = function (n) {
    if (typeof JSON == "undefined")
      throw new Error("Porthole serialization depends on JSON!");
    return JSON.stringify(n);
  };
  t.WindowProxy.unserialize = function (n) {
    if (typeof JSON == "undefined")
      throw new Error("Porthole unserialization dependens on JSON!");
    try {
      var t = JSON.parse(n);
    } catch (i) {
      return !1;
    }
    return t;
  };
  t.WindowProxy.getTargetWindow = function (t) {
    return t === ""
      ? parent
      : t === "top" || t === "parent"
      ? n[t]
      : n.frames[t];
  };
  t.MessageEvent = function (n, t, i) {
    this.data = n;
    this.origin = t;
    this.source = i;
  };
  t.WindowProxyDispatcher = {
    forwardMessageEvent: function () {
      var i,
        f = n.decodeURIComponent,
        u,
        r;
      document.location.hash.length > 0 &&
        ((i = t.WindowProxy.unserialize(f(document.location.hash.substr(1)))),
        (u = t.WindowProxy.getTargetWindow(i.targetWindowName)),
        (r = t.WindowProxyDispatcher.findWindowProxyObjectInWindow(
          u,
          i.sourceWindowName
        )),
        r
          ? r.origin === i.targetOrigin || i.targetOrigin === "*"
            ? r.dispatchEvent(new t.MessageEvent(i.data, i.sourceOrigin, r))
            : t.error(
                "Target origin " +
                  r.origin +
                  " does not match desired target of " +
                  i.targetOrigin
              )
          : t.error("Could not find window proxy object on the target window"));
    },
    findWindowProxyObjectInWindow: function (n, t) {
      var i;
      if (n)
        for (i in n)
          if (Object.prototype.hasOwnProperty.call(n, i))
            try {
              if (
                n[i] !== null &&
                typeof n[i] == "object" &&
                n[i] instanceof n.Porthole.WindowProxy &&
                n[i].getTargetWindowName() === t
              )
                return n[i];
            } catch (r) {}
      return null;
    },
    start: function () {
      n.addEventListener
        ? n.addEventListener(
            "resize",
            t.WindowProxyDispatcher.forwardMessageEvent,
            !1
          )
        : n.attachEvent && n.postMessage !== "undefined"
        ? n.attachEvent("onresize", t.WindowProxyDispatcher.forwardMessageEvent)
        : document.body.attachEvent
        ? n.attachEvent("onresize", t.WindowProxyDispatcher.forwardMessageEvent)
        : t.error("Cannot attach resize event");
    },
  };
  typeof exports != "undefined" ? (module.exports = t) : (n.Porthole = t);
})(typeof window != "undefined" ? window : this);
AgdSherpa.prototype._getLadingPath = function (n) {
  switch (n) {
    case 3:
      return "Static/Index";
    default:
      return "SherpaRender/SimpleSearchWidget";
  }
};
AgdSherpa.prototype.initialize = function () {
  var n = this;
  if (!this.container)
    throw "Invalid element id - " + this.id + " element does not exists ";
  if (this.isEmpty(this.name))
    throw (
      "Invalid element name - " + this.name + " element name can not be empty "
    );
  if ((this.buildSherpaRenderUrl(), this.isEmpty(this.sherpaRenderUrl)))
    throw "something went wrong! not able to generate url for search box";
  if (this.isEmpty(this.height)) throw "Invalid height";
  if (this.isEmpty(this.width)) throw "Invalid width";
  this.container.setAttribute(
    "style",
    "height:" +
      this.height +
      " !important; width:" +
      this.width +
      " !important;"
  );
  this.container.innerHTML =
    '<iframe src="' +
    this.sherpaRenderUrl +
    '" name="' +
    this.iframeName +
    '" id="' +
    this.iframeName +
    '" scrolling="no" frameborder="0" marginheight="0px" marginwidth="0px" style="height: 100% !important; width: 100% !important; min-width: ' +
    this.width +
    ' !important;" allowfullscreen></iframe>';
  document
    .getElementById(this.iframeName)
    .addEventListener("load", function () {
      n._sendParentWidth();
    });
};
AgdSherpa.prototype._sendParentWidth = function () {
  var n = this.getElementWidth(document.getElementById(this.id).parentElement);
  this.proxy.post({ action: "adjustwidth", width: n });
};
AgdSherpa.prototype.isEmpty = function (n) {
  return typeof n == "undefined" || n === null || n === "";
};
AgdSherpa.prototype.getElementWidth = function (n) {
  var i = n.offsetWidth,
    t,
    r;
  try {
    t = window.getComputedStyle(n);
    r = parseFloat(t.paddingLeft) + parseFloat(t.paddingRight);
    i = n.clientWidth - r;
  } catch (u) {
    console.log("Browser is not supported");
  }
  return i;
};
AgdSherpa.prototype._addEncodeQueryStringtoURL = function (n, t) {
  var i = this;
  i.isEmpty(t) ||
    (i.sherpaRenderUrl = i.sherpaRenderUrl
      .concat("&" + n + "=")
      .concat(encodeURIComponent(t)));
};
AgdSherpa.prototype._addEncodeBooleanQueryStringtoURL = function (n, t) {
  var i = this,
    r = t;
  r = i.isEmpty(t) ? !0 : t;
  i.sherpaRenderUrl = i.sherpaRenderUrl.concat("&" + n + "=").concat(r);
};
AgdSherpa.prototype.buildSherpaRenderUrl = function () {
  var n, t;
  if (this.isEmpty(this.baseUrl)) throw "Invalid base url - " + this.baseUrl;
  this.sherpaRenderUrl = this.protocol.concat(this.baseUrl).concat("?");
  for (n in this.sherpaQueryStringsConfiguration)
    (t = this.sherpaQueryStringsConfiguration[n].value),
      this.sherpaQueryStringsConfiguration[n].type == "string"
        ? this._addEncodeQueryStringtoURL(
            this.sherpaQueryStringsConfiguration[n].key,
            this.sherpaQueryStringsConfiguration[n].value
          )
        : this.sherpaQueryStringsConfiguration[n].type == "boolean" &&
          this._addEncodeBooleanQueryStringtoURL(
            this.sherpaQueryStringsConfiguration[n].key,
            this.sherpaQueryStringsConfiguration[n].value
          );
};
   var stg = new Object(); 
   stg.crt="9045851695588";
   stg.version="1.04"; 
   stg.id=stg.name="adgshp855357783";
    stg.width="320px"; 
    stg.height="420px";
    stg.ReferenceKey="kEpYKdO/zrL1AuGqk0Bvdw=="; 
    stg.Layout="TallCalendar"; 
    stg.Language="en-us";
    stg.Cid="1903645"; 
    var searchText =  this.$route.params.searchtext;
    stg.DestinationName=searchText;
    stg.OverideConf=false; 
    var delayInMilliseconds = 1000; //1 second
    setTimeout(function() {
          new AgdSherpa(stg).initialize();
    }, delayInMilliseconds);

  }
}



</script>
<style>
 #app{
   background-color: #040825
 }
 .bg{
   padding-top: 2rem;
 }
</style>